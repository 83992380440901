const initialState = {
    data: [],
    galleryData: [],
  };
  
  const layoutData = (state = initialState, action) => {
    switch (action.type) {
      case "GET_LAYOUT":
        return {
          ...state,
          data: action.data,
        };
      case "CREATE_LAYOUT":
        return { ...state };
      case "UPDATE_LAYOUT":
        return { ...state };
      case "DELETE_LAYOUT":
        return { ...state };
      case 'GET_GALLERY':
          return {
            ...state,
            galleryData: action.data
          }
      default:
        return { ...state };
    }
  };
  
  export default layoutData;
  