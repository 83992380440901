const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  centuryData: [],
}

const timeLineReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_TIME_LINE':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_TIME_LINE':
      return { ...state }
    case 'UPDATE_TIME_LINE':
      return { ...state }
    case 'GET_CENTUEY':
      return {
        ...state,
        centuryData: action.data
      }
   
    default:
      return {...state}
  }
}
export default timeLineReducer
