const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],
  };
  
  const galleryData = (state = initialState, action) => {
    switch (action.type) {
      case "GET_GALLERY":
        return {
          ...state,
          allData: action.allData,
          data: action.data,
          total: action.totalPages,
          params: action.params,
        };
      case "CREATE_GALLERY":
        return { ...state };
      case "UPDATE_GALLERY":
        return { ...state };
      default:
        return { ...state };
    }
  };
  
  export default galleryData;
  