// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import users from "./master/userMasterReduser";
import settingMasterData from "./master/settingMasterReducer";

import profile from './profile'
import formInformationMasterData from './master/formInfoMasterReducer'
import InfoData from './master/infoReducer'
import dashboardData from './dashboard/dashboardDataReducer'
import timeLineMasterData from './master/timeLineMasterReduser'
import centuryData from "./master/centuryMasterDataReducer";
import galleryData from "./master/galleryMasterDataReducer";
import layoutData from "./master/layoutMasterDataReducer";
import deviceRegistrationMasterData from './master/deviceRegMasterDataReducer';
import deviceLiveMasterData from './master/deviceLiveDataReducer';

  
const rootReducer = combineReducers({
  auth,
  profile,
  navbar,
  layout,
  users,
  settingMasterData,
  centuryData,
  deviceRegistrationMasterData,
  deviceLiveMasterData,
  formInformationMasterData,
  InfoData,
  dashboardData,
  timeLineMasterData,
  galleryData,
  layoutData
  
})

export default rootReducer;
